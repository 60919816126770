// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("graphics/logoback-f.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".thankyou-content h1{padding:0px;margin:0px}.thankyou-content .smallbox{position:absolute;width:400px;height:500px;z-index:15;top:50%;left:50%;margin:-250px 0 0 -200px;border-radius:25px;text-align:center;background-color:#fdfdfd;border:2px solid #dedede;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center bottom;background-repeat:no-repeat;background-size:100%;padding:12px}", "",{"version":3,"sources":["webpack://./src/ThankYou.scss"],"names":[],"mappings":"AAEI,qBACI,WAAA,CACA,UAAA,CAGJ,4BACI,iBAAA,CACA,WAAA,CACA,YAAA,CACA,UAAA,CACA,OAAA,CACA,QAAA,CACA,wBAAA,CACA,kBAAA,CACA,iBAAA,CACA,wBAAA,CACA,wBAAA,CACA,wDAAA,CACA,iCAAA,CACA,2BAAA,CACA,oBAAA,CACA,YAAA","sourcesContent":["\n.thankyou-content {\n    h1 {\n        padding: 0px;\n        margin: 0px;\n    }\n\n    .smallbox {\n        position: absolute;\n        width: 400px;\n        height: 500px;\n        z-index: 15;\n        top: 50%;\n        left: 50%;\n        margin: -250px 0 0 -200px;\n        border-radius: 25px;\n        text-align: center;\n        background-color: #fdfdfd;\n        border: 2px solid #dedede;\n        background-image: url(graphics/logoback-f.png);\n        background-position: center bottom;\n        background-repeat: no-repeat;\n        background-size: 100%;\n        padding: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
