"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const Yup = __importStar(require("yup"));
const schema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .matches(/[A-Z][a-z]*/, "First Name must start with a capital letter. E.g John")
        .required("First Name is required"),
    lastName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .matches(/[A-Z][a-z]*/, "Last Name must start with a capital letter. E.g Smith")
        .required("Last Name is required"),
    address: Yup.string().max(250).required("Address is required"),
    mobile: Yup.string().min(10, "Mobile too short").max(11, "Mobile too long"),
    homePhone: Yup.string().required("Phone number is required").min(10, "Home Phone too short").max(11, "Home Phone too long"),
    email: Yup.string().email("Email must be a valid email. e.g. johnsmith@email.com").required("Email is required"),
    space: Yup.string().oneOf(["dont_mind", "inside", "outside"]),
    tour1day1: Yup.boolean(),
    tour1day2: Yup.boolean(),
    tour2day1: Yup.boolean(),
    tour2day2: Yup.boolean(),
    tour3day1: Yup.boolean(),
    tour3day2: Yup.boolean(),
    tandc: Yup.bool().oneOf([true], 'Terms & Conditions must be checked'),
    privacy: Yup.bool().oneOf([true], 'Privacy Policy must be checked'),
    arrivalDate: Yup.date().required("Departure Date is required."),
    departureDate: Yup.date().required("Arrival Date is required."),
    exhibits: Yup.array()
        .of(Yup.object().shape({
        regyear: Yup.string()
            .matches(/^\d{4}$/, "Year is invalid. e.g. 1957")
            .required("Year is required"),
        make: Yup.string().required("Make is required. e.g Rudge"),
        model: Yup.string().required("Model is required"),
        reg: Yup.string()
            .optional()
            .matches(/^[A-Z0-9]*\s[A-Z0-9]*/g, "Registration must contain capital letters, numbers and a space. e.g BD51 SMR"),
        description: Yup.string().max(600).required("Description is required."),
        category: Yup.string().required("Category is required."),
    }))
        .min(1),
});
exports.default = schema;
