"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function hasErrors(exhibitor, errors) {
    let errorCount = Object.keys(errors).length;
    if (exhibitor.arrival === "local") {
        if (errors.departureDate) {
            errorCount -= 1;
        }
        if (errors.arrivalDate) {
            errorCount -= 1;
        }
    }
    return errorCount > 0;
}
exports.default = hasErrors;
